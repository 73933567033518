
// dev
// export const config =  {
//     baseUrl: 'https://pbapi.dev.boo2.nl',
//     portal_url: 'https://volmax.dev.boo2.nl',
// }

//check if prod or staging
let burl = 'https://pbapi.truckandtrace.no/' ; 
let purl = 'https://truckandtrace.no' ; 

if( window.location.hostname.includes("planboard.truckandtrace.no") ){
    burl = 'https://pbapi.truckandtrace.no/' ; 
    purl = 'https://truckandtrace.no' ; 
}
export const config =  {
    baseUrl: burl ,
    portal_url: purl ,
}