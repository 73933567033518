import React from "react";
import { Bars } from "react-loader-spinner";

const FullScreenLoader = ({ children, isLoading }) => {
  return (
    <div className="full-screen-loader-container">
      {isLoading && (
        <div className="loader">
          <Bars
            height="80"
            width="80"
            color="#56839F"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}

      {children}
    </div>
  );
};

export default FullScreenLoader;
